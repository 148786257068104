import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import ChoiceForm from "./component/choiceForm.js"

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          We need a logo
        </p>
        <a
          className="App-link"
          href="https://github.com/tyler1zhang/Advanture_of_Code"
          target="_blank"
          rel="noopener noreferrer"
        >
          Welcome to our adeventure of code
        </a>
        <ChoiceForm />
      </header>
    </div>
  );
}

export default App;
