
import React from 'react';
class ChoiceForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {day: "1", question: "1", user: "ZX", result:"0", running_time:"0"};
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        // alert('A choice was submitted: ' + [JSON.stringify(event.target.name)] + event.target.value);
    }

    handleSubmit(event) {
        const url = new URL("https://api.codeofcloud.com/app/functions/ZX")
        url.search = new URLSearchParams(this.state).toString()
        event.preventDefault()

        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            params: this.state
        })
            .then(response => {
                if (!response.ok) { throw response }
                console.log("response is ", response);
                return response.json()
            })
            .then(data => {
                console.log("data is", data); 
                console.log(typeof(data))
                return this.setState({result: data.result, running_time: data.running_time.toFixed(5)})})
            .catch(error => this.setState({result: "error code is "+error.status}))
            .then(event.preventDefault())

    }

    render() {
        return (
            <>
                <form style={{"font-size": "calc(5px + 2vmin)"}} onSubmit={this.handleSubmit}>
                <p>This is for AOC Code testing</p>
                <label>
                    Day:
                    <select style={{"margin":"10px"}} value={this.state.day} defaultValue="1" name="day" onChange={this.handleChange}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                    </select>
                </label>
                <label>
                    Question: 
                    <select style={{"margin":"10px"}} value={this.state.queston} defaultValue="1" name="question" onChange={this.handleChange}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                    </select>
                </label>
                <label>
                    User: 
                    <select style={{"margin":"10px"}} value={this.state.user} defaultValue="ZX" name="user" onChange={this.handleChange}>
                        <option value="ZX">ZX</option>
                    </select>
                </label>
                <br></br>
                <input style={{"margin":"10px", padding:10, fontSize:20, background:"cyan"}} type="submit" value="Run" />
                </form>
                <p style={{"font-size": "calc(5px + 2vmin)", "width":"80vw", "wordWrap": "break-word", "margin":"5px"}}>the result is: {this.state.result}</p>
                <p style={{"font-size": "calc(5px + 2vmin)", "margin":"5px"}}>the running time is: {this.state.running_time} seconds</p>

            </>
            );
        }
    }

export default ChoiceForm